import(/* webpackMode: "eager" */ "/vercel/path0/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/Map.png");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/images/pourquoi-travailler-avec-nous.jpg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/svg/building.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/svg/Deficients-auditifs-CMJN.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/svg/Deficients-mentaux-CMJN.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/svg/Deficients-moteur-CMJN.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/svg/Deficients-visuels-CMJN.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/svg/fast-arrow-right.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/svg/instagram.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/svg/linkedin.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/svg/map-pin.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/svg/message-text.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/svg/rocket.svg");
;
import(/* webpackMode: "eager" */ "/vercel/path0/public/svg/send-mail.svg");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/[lang]/components/Calandly.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/vercel/path0/src/app/[lang]/components/LandingTravaillerAvecNous.tsx");
